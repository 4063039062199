import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import { Button, Spin, Upload, message } from 'antd';
import resumeUploadBg from 'assets/uniprofileResume/resumeUploadBg.png';
import type { UploadProps } from 'antd';
import { uploadUniprofile } from 'api/uniprofileResume';
import { RcFile } from 'antd/es/upload';
import { setAvaDocumentId } from 'utils/localstore';

interface IProps {
    onUploadSuccess: (id?: string) => void;
}

const UploadUniprofilePage = ({
    onUploadSuccess,
}: IProps) => {
    const [loading, setLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<RcFile | null>();
    const props: UploadProps = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },
        showUploadList: false,
        multiple: false,
        accept: '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt',
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const isLt10M = file.size / 1024 / 1024 <= 10;
                if (!isLt10M) {
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        customRequest: async (data) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append('file', data.file);
                const res = await uploadUniprofile(formData);
                if (!res.error) {
                    const documentId = res.data?.data;
                    onUploadSuccess(res.data?.data);
                    setUploadedFile(data.file as RcFile);
                    setAvaDocumentId(documentId);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        },
    };

    const handleRemove = useCallback(() => {
        onUploadSuccess();
        setUploadedFile(null);
    }, [onUploadSuccess]);

    return (
        <div className={s.wrap}>
            <div className={s.left}>
                <div className={s.inroBox}>
                    <img className={s.introImage} src={resumeUploadBg} />
                </div>
            </div>
            <div className={s.right}>
                <div className={s.title}>Upload your resume</div>
                <div className={s.desc}>
                    <p>Welcome to the next step in creating your UniProfile. Upload your resume and Ava, our AI, will do the rest. Accepting both PDF and Word, Ava skillfully extracts your details for your profile.</p>
                    <p className={s.steps}>Quick Steps:</p>
                    <ul>
                        <li>Upload: Drag and drop your resume.</li>
                        <li>AI Drafting: Ava turns your resume into a UniProfile draft.</li>
                        <li>Final Touches: Review and tweak as needed.</li>
                    </ul>
                    <p>Enjoy streamlined, automated profile setup with Ava, making your digital practice transition effortless.</p>
                </div>
                <Spin spinning={loading}>
                    {
                        !uploadedFile ?
                            <div className={s.upload}>
                                <Upload.Dragger {...props}>
                                    <div className={s.uploadBox}>
                                        <div className={s.uploadIcon} />
                                        <div className={s.title}>Upload your resume here</div>
                                        <div className={s.desc}>click to upload or drag your file and drop</div>
                                        <div className={s.desc}>PDF, .word, .txt up to 10MB</div>
                                    </div>
                                </Upload.Dragger>
                            </div>
                            :
                            <div className={s.fileBox}>
                                <div className={s.fileInfo}>
                                    <div className={s.fileIcon} />
                                    <div className={s.fileName}>{uploadedFile.name}</div>
                                </div>
                                <Button danger onClick={handleRemove}>Remove</Button>
                            </div>
                    }
                </Spin>
            </div>
        </div>
    );
};

export default UploadUniprofilePage;
