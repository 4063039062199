import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CloudImage from 'assets/uniprofileResume/cloud.svg';
import ProfileImage from 'assets/uniprofileResume/profileInroImage.png';
import EducationImage from 'assets/uniprofileResume/educationInroImage.png';
import ServiceImage from 'assets/uniprofileResume/serviceIntroImage.png';
import SpecialtiesImage from 'assets/uniprofileResume/specialtiesIntroImage.png';
import LicenseImage from 'assets/uniprofileResume/licenseIntroImage.png';
import ReviewsImage from 'assets/uniprofileResume/reviewsImage.png';
import { Progress } from 'antd';
import { getResumeAnalysisProcess } from 'api/uniprofileResume';
import { ResumeStepName } from 'pages/UniprofileResume/types';
import { getAvaDocumentId } from 'utils/localstore';
import useGetUniprofileResumeInfo from 'hooks/useGetUniprofileResumeInfo';

interface IProps {
    // documentId?: string;
    onNext: () => void;
    onFinish?: () => void;
}

SwiperCore.use([Autoplay]);

const AnalysisProcess = ({
    // documentId,
    onNext,
    onFinish,
}: IProps) => {
    const documentId = getAvaDocumentId();
    // const [timeCounter, setTimeCounter] = useState(0);
    const [steps, setSteps] = useState({
        [ResumeStepName.PROFILE]: false,
        [ResumeStepName.HEALTHCARE_EXPERIENCE]: false,
        [ResumeStepName.EDUCATION]: false,
        [ResumeStepName.ADDITIONAL_INFORMATION]: false,
        [ResumeStepName.PRACTICE]: false,
        ABOUTME: false,
    });
    const [hasSent, setHasSent] = useState(false);
    const loopInstance = useRef<ReturnType<typeof setInterval>>();
    const analysisPercentage = useRef(100);
    const intervalTime = useRef(5000);
    const [,infoLoading,,fetchResuInfo] = useGetUniprofileResumeInfo();

    // const counterInstance = useRef<ReturnType<typeof setInterval>>();

    // const setTimeCouter = useCallback(() => {
    //     if (!counterInstance.current) {
    //         counterInstance.current = setInterval(() => {
    //             setTimeCounter(timeCounter + 1);
    //         }, 1000);
    //     }
    // }, [timeCounter]);

    // useEffect(() => {
    //     setTimeCouter();
    // }, [setTimeCouter]);

    const startAnalysisRequests = useCallback(async (id: string, step: string) => {
        return getResumeAnalysisProcess({
            documentId: id,
            step,
        });
        // try {
        //     const requestResult = await getResumeAnalysisProcess({
        //         documentId: id,
        //         step,
        //     });
        //     if (requestResult && !requestResult.error && requestResult.data.data !== false) {
        //         setSteps({
        //             ...steps,
        //             [step]: true,
        //         });
        //     }
        // } catch (e) {
        //     console.error(e);
        // }
    }, []);

    // const getAboutme = useCallback(async (id: string) => {
    //     try {
    //         const requestResult = await getResumeAnalysisProcess({
    //             documentId: id,
    //             step: 'ABOUTME',
    //         });
    //         if (requestResult && !requestResult.error) {
    //             setSteps({
    //                 ...steps,
    //                 aboutMe: true,
    //             });
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }, [steps]);

    const destoryInterval = useCallback(() => {
        if (loopInstance.current) {
            clearInterval(loopInstance.current);
        }
    }, []);

    const fetchData = useCallback(async (id: string) => {
        const unfinishedSteps = Object.entries(steps).filter(([, v]) => !v);
        const requests = unfinishedSteps.map(([step]) => {
            return startAnalysisRequests(id, step);
        });

        try {
            const results = await Promise.all(requests);
            unfinishedSteps.forEach(([stepKey], index) => {
                const result = results[index]?.data?.data;

                if (result) {
                    steps[stepKey] = true;

                    setSteps({
                        ...steps,
                    });
                }
            });
        } catch (e) {
            console.error(e);
        }
        // getAboutme(id);
    }, [startAnalysisRequests, steps]);

    const loopGetData = useCallback((id: string) => {
        if (!loopInstance.current) {
            fetchData(id);
            loopInstance.current = setInterval(() => {
                fetchData(id);
            }, intervalTime.current);
        }
    }, [fetchData]);

    useEffect(() => {
        if (documentId && !hasSent) {
            loopGetData(documentId);
            setHasSent(true);
        }
    }, [documentId, hasSent, loopGetData]);

    const percentage = useMemo(() => {
        const result = (Object.values(steps).filter((e) => e).length / Object.keys(steps).length) * analysisPercentage.current;
        return result === 0 ? 5 : result;
    }, [steps]);

    const fetchFinished = useCallback(async () => {
        // clearInterval(counterInstance.current);
        await fetchResuInfo(documentId as string);
        destoryInterval();
        onNext();
        onFinish?.();
    }, [destoryInterval, documentId, fetchResuInfo, onFinish, onNext]);

    useEffect(() => {
        if (Object.values(steps).filter((v) => !!v).length === Object.keys(steps).length) {
            fetchFinished();
        }
    }, [fetchFinished, steps]);

    useEffect(() => {
        return () => {
            destoryInterval();
        };
    }, [destoryInterval]);

    return (
        <div className={s.wrap}>
            {/* <div style={{ fontSize: '20px' }}>{timeCounter}</div> */}
            <div className={s.contentWrap}>
                <div className={s.descWrap}>
                    <div className={s.inner}>
                        <img className={s.cloud} src={CloudImage} />
                        <Swiper
                            autoplay
                            loop
                            // allowTouchMove={false}
                            // slidesPerView={1}
                        >
                            <SwiperSlide>
                                <div className={s.content}>
                                    <div className={s.left}>
                                        <div className={s.title}>Education matters to patients:</div>
                                        <div className={s.desc}>{'Around 47% of patients regard a healthcare provider\'s education as a crucial decision-making factor. Highlight your educational achievements prominently!'}</div>
                                    </div>
                                    <div className={s.right}>
                                        <img src={EducationImage} />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={s.content}>
                                    <div className={s.left}>
                                        <div className={s.title}>Professional photos draw attention:</div>
                                        <div className={s.desc}>Adding a professional photo to your profile increases patient interest. A warm, friendly smile can make you seem more approachable and trustworthy.</div>
                                    </div>
                                    <div className={s.right}>
                                        <img src={ProfileImage} />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={s.content}>
                                    <div className={s.left}>
                                        <div className={s.title}>Highlight your specialties:</div>
                                        <div className={s.desc}>Detailing your medical specialties and approaches can greatly influence patient preferences. It helps patients find the perfect healthcare match!</div>
                                    </div>
                                    <div className={s.right}>
                                        <img src={SpecialtiesImage} />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={s.content}>
                                    <div className={s.left}>
                                        <div className={s.title}>License display equals trust:</div>
                                        <div className={s.desc}>Patients trust providers who showcase their licenses and certifications. It’s a mark of credibility and professionalism.</div>
                                    </div>
                                    <div className={s.right}>
                                        <img src={LicenseImage} />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={s.content}>
                                    <div className={s.left}>
                                        <div className={s.title}>Service Transparency Attracts:</div>
                                        <div className={s.desc}>Clear information about your services, pricing, and accepted insurance breeds patient confidence and trust, leading to higher engagement.</div>
                                    </div>
                                    <div className={s.right}>
                                        <img src={ServiceImage} />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={s.content}>
                                    <div className={s.left}>
                                        <div className={s.title}>Leverage reviews for reputation:</div>
                                        <div className={s.desc}>With 87% of consumers influenced by reviews, Kiwi Health’s reputation management feature helps you utilize patient feedback to boost your profile.</div>
                                    </div>
                                    <div className={s.right}>
                                        <img src={ReviewsImage} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className={s.processIntro}>
                    <p>This process may take 1-3 minutes.</p>
                    <p><strong>Please keep this window open</strong> - closing it will interrupt the upload and lose your progress.</p>
                    <div className={s.progressBar}>
                        <Progress className={s.progress} percent={percentage} showInfo={false} strokeColor="#00816B" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalysisProcess;
