import { useCallback, useEffect, useState } from 'react';
import { getKlarityServiceTypeList } from 'api/operation';
import { EContactType } from 'types/common';
import { IKlarityServiceType, EMandatoryOptions, ENewPatientAccept } from 'types/serviceTemplate';
// import { PRESET_PATIENT_ACCEPTED, PRESET_SERVICE_LIMITATIONS } from 'constants/common';
import { generateNewItem } from 'utils/serviceTemplate';

const defaultDescription = 'An initial visit with a provider involves a medical history review, an assessment of your specific symptoms and concerns, and possible diagnostic tests to evaluate a patient\'s health, identify issues, and develop and discuss treatment options.';

const transData = (listData: IKlarityServiceType[]) => {
    return listData.map((item: IKlarityServiceType) => {
        const { id, description, mandatory, newPatient, teleHealth, inPerson, klarityServiceTypePatientGroupList, klarityServiceTypeLimitationList } = item;

        // 处理contactType
        const contactType = [];
        if (teleHealth) {
            contactType.push(EContactType.TELE_HEALTH);
        }
        if (inPerson) {
            contactType.push(EContactType.IN_PERSON);
        }

        // 处理Patient Group和limitation 预设
        const klarityServiceTypePatientGroupListData = klarityServiceTypePatientGroupList || [];
        // if ((klarityServiceTypePatientGroupList && klarityServiceTypePatientGroupList.length === 0) || !klarityServiceTypePatientGroupList) {
        //     klarityServiceTypePatientGroupListData = PRESET_PATIENT_ACCEPTED.map((text: string, i: number) => ({
        //         presetId: i + 1,
        //         serviceTypeId: id,
        //         patientGroup: text,
        //         sequence: i + 1,
        //         type: 'patientGroup',
        //     }));
        // }

        const klarityServiceTypeLimitationListData = klarityServiceTypeLimitationList || [];
        // if ((klarityServiceTypeLimitationList && klarityServiceTypeLimitationList.length === 0) || !klarityServiceTypeLimitationList) {
        //     klarityServiceTypeLimitationListData = PRESET_SERVICE_LIMITATIONS.map((text: string, i: number) => ({
        //         presetId: i + 1,
        //         serviceTypeId: id,
        //         limitation: text,
        //         sequence: i + 1,
        //         type: 'limitation',
        //     }));
        // }

        klarityServiceTypePatientGroupListData?.push({
            ...generateNewItem(klarityServiceTypeLimitationListData.length, id, 'patientGroup', klarityServiceTypeLimitationListData[klarityServiceTypeLimitationListData.length - 1].sequence),
            isPlaceholder: true,
        });
        klarityServiceTypeLimitationListData?.push({
            ...generateNewItem(klarityServiceTypeLimitationListData.length, id, 'limitation'),
            isPlaceholder: true,
        });

        return {
            ...item,
            description: description || defaultDescription,
            isMandatory: mandatory ? EMandatoryOptions.MANDATORY : EMandatoryOptions.OPTIONAL,
            newPatient: newPatient ? ENewPatientAccept.YES : ENewPatientAccept.NO,
            showContactTypes: teleHealth || inPerson,
            showPatientGroupNLimitation: false,
            contactType,
            klarityServiceTypePatientGroupList: klarityServiceTypePatientGroupListData?.map((e) => ({ ...e, type: 'patientGroup' })),
            klarityServiceTypeLimitationList: klarityServiceTypeLimitationListData?.map((e) => ({ ...e, type: 'limitation' })),
        };
    });
};

const useGetServiceTypeList = (): [IKlarityServiceType[], boolean, string | undefined, () => void] => {
    const [data, setData] = useState<IKlarityServiceType[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>('');

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const res = await getKlarityServiceTypeList();

            if (res && !res.error) {
                setData(transData(res.data.data));
            } else {
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error, fetch];
};

export default useGetServiceTypeList;
