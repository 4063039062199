import React from 'react';
import {
    CloseOutlined,
} from '@ant-design/icons';
import s from './s.module.less';
import notes from './notes.svg';

interface IProps {
    defaultShow?: boolean;
    title?: React.ReactElement | string;
    icon?: React.ReactElement;
    des?: React.ReactElement | string;
    onTry?: () => void;
}

const Notes = ({
    defaultShow = true,
    title,
    icon,
    des,
    onTry,
}: IProps) => {
    const [show, setShow] = React.useState(defaultShow);
    if (!show) {
        return null;
    }
    return (
        <div className={s.wrap}>
            <div className={s.icon}>
                {icon || <img src={notes} />}
            </div>
            <div className={s.info}>
                <div className={s.title}>{title}</div>
                <div className={s.content}>{des}</div>
            </div>
            <div className={s.check} onClick={onTry}>
                Try it
            </div>
            <div
                onClick={() => {
                    setShow(false);
                }}
                className={s.close}
            >
                <CloseOutlined />
            </div>
        </div>
    );
};

export default Notes;
