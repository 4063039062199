import React, { useCallback } from 'react';
import s from './s.module.less';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import capitalize from 'lodash/capitalize';

interface IProps {
    step: StepValue;
    fields: string[];
}

const UniprofileCheckFinishedTooltipBar = ({
    step,
    fields,
}: IProps) => {
    const handleText = useCallback((step, e) => {
        if (e.toString().indexOf('.') > -1) {
            return e.split('.')?.[1];
        }

        return typeof e === 'number' ? `${capitalize(step)} ${e + 1}` : e?.text?.toString().replace(/\[.*\]/g, '');
    }, []);

    const handleTextHack = useCallback((step, e) => {
        if (e.toString().indexOf('.') > -1) {
            return e.split('.')?.[1];
        }

        if (typeof e === 'number') {
            return `${capitalize(step)} ${e + 1}`;
        } else if (e.anchorId) {
            return e.anchorId;
        }

        return e?.fieldName?.toString()?.replace(/\[.*\]/g, '');
    }, []);
    return (fields && fields.length > 0) ? (
        <div className={s.wrap}>
            <div className={s.robot} />
            <div className={s.content}>
                <div className={s.title}>
                    <span>Ava</span>
                    has pre-filled your profile from your resume; some fields still require your input.
                </div>
                <div className={s.fieldsList}>
                    {
                        fields.map((e: string | number) => <a href={`#${handleTextHack(step, e).replace(/\s/g, '_')}`}>{handleText(step, e)}</a>)
                    }
                </div>
            </div>
        </div>
    ) : (
        <div className={s.wrap}>
            <div className={s.content}>
                <div className={s.title}>
                    <div className={s.congrats}>🎉</div>
                    <span>Ava</span>
                    pre-filled your {capitalize(step)}. Please confirm and proceed to the next section!
                </div>
            </div>
        </div>
    );
};

export default UniprofileCheckFinishedTooltipBar;
