import { EContactType } from 'types/common';
import { EAddOnProductCode } from 'types/ehr';
import { EnumFields } from 'types/enumerationData';
import { PATH } from './path';

export const DEFAULT_SECOND_TYPE = '1';
export const GOOGLE_ADMIN_URL = 'https://admin.google.com/ac/users';

export const DURATION_LIST = (() => {
    const ret = [];
    for (let i = 0; i <= 90; i += 5) {
        ret.push(
            {
                label: `${i}`,
                value: i,
            },
        );
    }
    return ret;
})();
export const ERROR_CODE = {
    TOKEN_UN_VERIFY: 4001,
};

export const CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY =
  'channelAppointmentStep';

export const INTAKEFORM_CREATE_URL = 'https://intakeq.com/#/myforms';

export const SUPPORT_TIME_ZONE = [
    {
        dictKey: 'America/Thule',
        offset: '-04',
    },
    {
        dictKey: 'America/New_York',
        offset: '-05',
    },
    {
        dictKey: 'America/Chicago',
        offset: '-06',
    },
    {
        dictKey: 'America/Phoenix',
        offset: '-07',
    },
    {
        dictKey: 'America/Los_Angeles',
        offset: '-08',
    },
    {
        dictKey: 'America/Anchorage',
        offset: '-09',
    },
    {
        dictKey: 'America/Adak',
        offset: '-10',
    },
    {
        dictKey: 'Asia/Shanghai',
        offset: '+08',
    },
];

export const SINGLE_TYPE_SINGLE_SERVICE = 'SINGLE_TYPE_SINGLE_SERVICE';

export const TERMS_LINK = 'https://www.kiwihealth.com/terms-conditions';
export const PRIVACY_LINK = 'https://www.kiwihealth.com/privacy-policy';
export const HELP_LINK = 'https://support.kiwihealth.com/support';
export const UNIPROFILE = 'uniprofile';
export const PRACTICE_FRONT = 'practiceFront';
// 拥有preview 页面的channel id
export const PREVIEW_GOOGLE_NAME = 'Google';
export const PREVIEW_ZOCDOC_NAME = 'Zocdoc';
export const PREVIEW_YELP_NAME = 'Yelp Basic';
// about me的长度限制
export const IntroTextLength = 2000;
export const TreatmentPhilosophyTextLength = 800;

// practice front 锚点
export enum EmptyFields {
    WorkExperience = 'Work experience',
    Education = 'Education',
    MyPhilosophy = 'Treatment Methodology',
    SpecialtiesApproaches = 'Specialties & Approaches',
}

export const PRACTICE_FRONT_FROM = 'pf';
export const CHANNEL_DETAIL = 'channelDetail';
export const HOME = 'home';

export const PRE_LOGIN_PAGE = [PATH.HOME, `/${PATH.LOGIN}`, `/${PATH.PROVIDER_LOGIN}`];

export const EMPTY_PLACEHOLDER = 'Not provided';
export const NOT_SET = 'Not set';
export const REQUIRED_FIELD = 'This field is required';

export const INSURANCE_TYPE_CASH = 'CASH';
export const INSURANCE_TYPE_INSURANCE = 'INSURANCE';
export const INSURANCE_TYPE_CASH_AND_INSURANCE = 'CASH_AND_INSURANCE';
export const PAY_TYPE_TEXT = {
    [INSURANCE_TYPE_CASH]: 'I only support cash pay.',
    [INSURANCE_TYPE_CASH_AND_INSURANCE]: 'I support both cash pay and insurance.',
    [INSURANCE_TYPE_INSURANCE]: 'I only accept insurance.',
};

// withdraw  页面应该是free对应free, paid对应到standard
// premium对应premium

export const ChannelPayTypeFilterMapKey = {
    standard: 'standard',
    premium: 'premium',
    free: 'free',
};

export const channelPayTypeFilterMap = {
    [ChannelPayTypeFilterMapKey.free]: ['free'],
    [ChannelPayTypeFilterMapKey.standard]: ['paid'],
    [ChannelPayTypeFilterMapKey.premium]: ['premium'],
};

export const DEFAULT_CHANNEL_LISTING_LIMITATION = {
    free: 2,
    standard: 6,
};

export const PAYMENT_WEBSITE_NAME = 'stripe';

export const CHANNEL_STORE = 'channelstore';
export const MY_CHANNELS = 'mychannels';

export const HTML_DIV_ID = {
    LOCAL_SEO_WRAP: 'localSeoWrap',
    PF_HOME_WRAP: 'pfHomeWrap',
};

export const PF_POI_AUTO_GEN_MAX_ACCOUNT = 10;

export const PATIENT_TEMPLATE_GOOGLE_DOC_URL = 'https://docs.google.com/spreadsheets/d/1TYgCz_r7Z936YxkXLIUSIv1BJzopKnpVqAz92WxrDLo/edit#gid=0';

export const PF_CHANNEL_ID = 999999999;

export const OTHERS_CHANNEL_ID = -1;

export const PF_CHANNEL_NAME = 'Practice Front';

export const OTHERS_CHANNEL_NAME = 'Others';

export const UNIPROFILE_CREATION_ALISA = 'uc';

export const HELLO_KLARITY_PAGE_TYPE = [
    {
        label: 'Home',
        value: 'HOME',
    }, {
        label: 'Condition',
        value: 'CONDITION',
    }, {
        label: EnumFields.STATE,
        value: EnumFields.STATE,
    },
    {
        label: 'Faqs',
        value: 'FAQS',
    },
];

export const HelloKlarityProviderDraftStorageKey = 'HELLOKLARITY_PROVIDER_DRAFT_STORAGE';

// export const PRE_RELEASE_ORIGIN = isProductionEnv() ? 'https://prerelease.helloklarity.com' : '';
export const PRE_RELEASE_ORIGIN = '';

export const REQUIRE_FIELD_TIPS = 'This is a required field';
export const URL_LIMIT = 512;

export const HELP_EMAIL = 'onboarding@helloklarity.com';

export const SERVICE_TYPES = [
    '👋 New patient initial visit',
    '🔄 Established patient visit',
    '💬 Consultation',
    '💊 Medication refill',
    '📝 Other patient support (documentations, lab, etc.)',
];

export const CONTACT_TYPES_OPTIONS = [
    {
        label: EContactType.TELE_HEALTH,
        value: EContactType.TELE_HEALTH,
    },
    {
        label: EContactType.IN_PERSON,
        value: EContactType.IN_PERSON,
    },
];

export const PRESET_PATIENT_ACCEPTED = [
    'Children (5-12 years old)',
    'Adolescents (13-17 years old)',
    'Adults (18-65 years old)',
    'Seniors (65+ years old)',
    'Couples and family',
    'Group session',
];

export const PRESET_SERVICE_LIMITATIONS = [
    'Cannot see patients currently pregnant, nursing, or trying to conceive.',
    'Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.',
    'Unable to see patients with recent mental health hospitalizations, legal holds (e.g., 5150/5250), or diagnosed personality disorders.',
    'Cannot see patients with certain medical conditions: heart issues, hypertension, or on medications like narcotics/opiates/Suboxone.',
    'Patients in addiction recovery must provide a confirmed sobriety date.',
    'Unable to treat patients diagnosed with eating disorders.',
];

export const SNAPSHOT_COLOR = '#1677ff';

export const AllState = [
    {
        id: 17,
        type: EnumFields.STATE,
        dictKey: 'AL',
        content: 'Alabama',
        description: null,
    },
    {
        id: 18,
        type: EnumFields.STATE,
        dictKey: 'AK',
        content: 'Alaska',
        description: null,
    },
    {
        id: 133,
        type: EnumFields.STATE,
        dictKey: 'AZ',
        content: 'Arizona',
        description: null,
    },
    {
        id: 134,
        type: EnumFields.STATE,
        dictKey: 'AR',
        content: 'Arkansas',
        description: null,
    },
    {
        id: 135,
        type: EnumFields.STATE,
        dictKey: 'CA',
        content: 'California',
        description: null,
    },
    {
        id: 136,
        type: EnumFields.STATE,
        dictKey: 'CO',
        content: 'Colorado',
        description: null,
    },
    {
        id: 137,
        type: EnumFields.STATE,
        dictKey: 'CT',
        content: 'Connecticut',
        description: null,
    },
    {
        id: 138,
        type: EnumFields.STATE,
        dictKey: 'DE',
        content: 'Delaware',
        description: null,
    },
    {
        id: 139,
        type: EnumFields.STATE,
        dictKey: 'DC',
        content: 'District of Columbia',
        description: null,
    },
    {
        id: 140,
        type: EnumFields.STATE,
        dictKey: 'FL',
        content: 'Florida',
        description: null,
    },
    {
        id: 141,
        type: EnumFields.STATE,
        dictKey: 'GA',
        content: 'Georgia',
        description: null,
    },
    {
        id: 142,
        type: EnumFields.STATE,
        dictKey: 'HI',
        content: 'Hawaii',
        description: null,
    },
    {
        id: 143,
        type: EnumFields.STATE,
        dictKey: 'ID',
        content: 'Idaho',
        description: null,
    },
    {
        id: 144,
        type: EnumFields.STATE,
        dictKey: 'IL',
        content: 'Illinois',
        description: null,
    },
    {
        id: 145,
        type: EnumFields.STATE,
        dictKey: 'IN',
        content: 'Indiana',
        description: null,
    },
    {
        id: 146,
        type: EnumFields.STATE,
        dictKey: 'IA',
        content: 'Iowa',
        description: null,
    },
    {
        id: 147,
        type: EnumFields.STATE,
        dictKey: 'KS',
        content: 'Kansas',
        description: null,
    },
    {
        id: 148,
        type: EnumFields.STATE,
        dictKey: 'KY',
        content: 'Kentucky',
        description: null,
    },
    {
        id: 149,
        type: EnumFields.STATE,
        dictKey: 'LA',
        content: 'Louisiana',
        description: null,
    },
    {
        id: 150,
        type: EnumFields.STATE,
        dictKey: 'ME',
        content: 'Maine',
        description: null,
    },
    {
        id: 151,
        type: EnumFields.STATE,
        dictKey: 'MD',
        content: 'Maryland',
        description: null,
    },
    {
        id: 152,
        type: EnumFields.STATE,
        dictKey: 'MA',
        content: 'Massachusetts',
        description: null,
    },
    {
        id: 153,
        type: EnumFields.STATE,
        dictKey: 'MI',
        content: 'Michigan',
        description: null,
    },
    {
        id: 154,
        type: EnumFields.STATE,
        dictKey: 'MN',
        content: 'Minnesota',
        description: null,
    },
    {
        id: 155,
        type: EnumFields.STATE,
        dictKey: 'MS',
        content: 'Mississippi',
        description: null,
    },
    {
        id: 156,
        type: EnumFields.STATE,
        dictKey: 'MO',
        content: 'Missouri',
        description: null,
    },
    {
        id: 157,
        type: EnumFields.STATE,
        dictKey: 'MT',
        content: 'Montana',
        description: null,
    },
    {
        id: 158,
        type: EnumFields.STATE,
        dictKey: 'NE',
        content: 'Nebraska',
        description: null,
    },
    {
        id: 159,
        type: EnumFields.STATE,
        dictKey: 'NV',
        content: 'Nevada',
        description: null,
    },
    {
        id: 160,
        type: EnumFields.STATE,
        dictKey: 'NH',
        content: 'New Hampshire',
        description: null,
    },
    {
        id: 161,
        type: EnumFields.STATE,
        dictKey: 'NJ',
        content: 'New Jersey',
        description: null,
    },
    {
        id: 162,
        type: EnumFields.STATE,
        dictKey: 'NM',
        content: 'New Mexico',
        description: null,
    },
    {
        id: 163,
        type: EnumFields.STATE,
        dictKey: 'NY',
        content: 'New York',
        description: null,
    },
    {
        id: 164,
        type: EnumFields.STATE,
        dictKey: 'NC',
        content: 'North Carolina',
        description: null,
    },
    {
        id: 165,
        type: EnumFields.STATE,
        dictKey: 'ND',
        content: 'North Dakota',
        description: null,
    },
    {
        id: 166,
        type: EnumFields.STATE,
        dictKey: 'OH',
        content: 'Ohio',
        description: null,
    },
    {
        id: 167,
        type: EnumFields.STATE,
        dictKey: 'OK',
        content: 'Oklahoma',
        description: null,
    },
    {
        id: 168,
        type: EnumFields.STATE,
        dictKey: 'OR',
        content: 'Oregon',
        description: null,
    },
    {
        id: 169,
        type: EnumFields.STATE,
        dictKey: 'PA',
        content: 'Pennsylvania',
        description: null,
    },
    {
        id: 170,
        type: EnumFields.STATE,
        dictKey: 'RI',
        content: 'Rhode Island',
        description: null,
    },
    {
        id: 171,
        type: EnumFields.STATE,
        dictKey: 'SC',
        content: 'South Carolina',
        description: null,
    },
    {
        id: 172,
        type: EnumFields.STATE,
        dictKey: 'SD',
        content: 'South Dakota',
        description: null,
    },
    {
        id: 173,
        type: EnumFields.STATE,
        dictKey: 'TN',
        content: 'Tennessee',
        description: null,
    },
    {
        id: 174,
        type: EnumFields.STATE,
        dictKey: 'TX',
        content: 'Texas',
        description: null,
    },
    {
        id: 175,
        type: EnumFields.STATE,
        dictKey: 'UT',
        content: 'Utah',
        description: null,
    },
    {
        id: 176,
        type: EnumFields.STATE,
        dictKey: 'VT',
        content: 'Vermont',
        description: null,
    },
    {
        id: 177,
        type: EnumFields.STATE,
        dictKey: 'VA',
        content: 'Virginia',
        description: null,
    },
    {
        id: 178,
        type: EnumFields.STATE,
        dictKey: 'WA',
        content: 'Washington',
        description: null,
    },
    {
        id: 179,
        type: EnumFields.STATE,
        dictKey: 'WV',
        content: 'West Virginia',
        description: null,
    },
    {
        id: 180,
        type: EnumFields.STATE,
        dictKey: 'WI',
        content: 'Wisconsin',
        description: null,
    },
    {
        id: 181,
        type: EnumFields.STATE,
        dictKey: 'WY',
        content: 'Wyoming',
        description: null,
    },
];

export enum ESpecialty {
    'PSYCHIATRIC_MENTAL_HEALTH' = 'Psychiatric & Mental Health',
    'COUNSELING_THERAPY' = 'Counseling & Therapy',
    'WEIGHT_LOSS' = 'Weight Loss',
    'DERMATOLOGY' = 'Dermatology',
    'INFECTIONS' = 'Infections',
    'MENS_HEALTH' = "Men's Health",
    'WOMENS_HEALTH' = "Women's Health",
    'PREVENTIVE_PRIMARY_CARE' = 'Preventive & Primary Care',
}

export const SPECIALTY_TIPS_MAP:Record<ESpecialty, { reminder:string, toolTips:string }> = {
    [ESpecialty.PSYCHIATRIC_MENTAL_HEALTH]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “anxiety treatment” as one service and put “ADHD treatment” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, anxiety treatment, ADHD treatment, etc.',
    },
    [ESpecialty.COUNSELING_THERAPY]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “couple therapy” as one service and put “grief counseling” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, couple therapy, grief counseling, etc.',
    },
    [ESpecialty.WEIGHT_LOSS]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “weight loss medication treatment” as one service and put “lifestyle counseling” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, weight loss medication treatment, lifestyle counseling, etc.',
    },
    [ESpecialty.DERMATOLOGY]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “skin infection treatment” as one service and put “acne treatment” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, skin infection treatment, acne treatment, etc.',
    },
    [ESpecialty.INFECTIONS]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “yeast infection treatment” as one service and put “UTI treatment” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, yeast infection treatment, UTI treatment, etc.',
    },
    [ESpecialty.MENS_HEALTH]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “erectile dysfunction treatment” as one service and put “sexual health counseling” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, erectile dysfunction treatment, sexual health counseling, etc.',
    },
    [ESpecialty.WOMENS_HEALTH]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “infertility treatment” as one service and put “menopause treatment” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, infertility treatment, menopause treatment etc.',
    },
    [ESpecialty.PREVENTIVE_PRIMARY_CARE]: {
        reminder: 'Input a condition treated or a treatment method. It’s encouraged to differentiate the price for different conditions depending on demand. For example, you could put “routine check-ups” as one service and put “asthma treatment” as another service. ',
        toolTips: 'Input a condition treated or a treatment method. For example, Covid-19 vaccination, asthma treatment, routine check-up, etc.',
    },
};

// 用来管理Booking link 的本地草稿版本用，不要轻易修改！！！
// 因为如果修改了，会导致删除掉localstorage内之前存的草稿
export const DRAFT_STORAGE_VERSION = '';

export const SERVER_PRODUCT_ID_FE_TYPE_MAP:Record<string, EAddOnProductCode> = {

};

export const IS_BACK_FROM_STRIPE = 'isBackFromStripe';

export const SHOW_KLARITY_ADVERTISING_ICON = 'showKlarityPlanAdvertisingIcon';
