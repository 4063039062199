import React from 'react';
import s from './s.module.less';
import TextArea from 'antd/es/input/TextArea';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage, DomEditor } from '@wangeditor/editor';
import { getUserToken } from 'utils/localstore';
import '@wangeditor/editor/dist/css/style.css';
// 引入 css
i18nChangeLanguage('en');

type HtmlContentInputProps = {
    value?: string;
    onChange?: (val?: string) => void,
    header: React.ReactNode,
    footer: React.ReactNode,
    height?: string,
    config?: Partial<IToolbarConfig>,
    isClosed?: boolean,
};
//https://www.wangeditor.com/
const HtmlContentInput = (Props:HtmlContentInputProps) => {
    const { onChange, value, header, footer, height, config, isClosed } = Props;

    const [editor, setEditor] = React.useState<IDomEditor | null>(null); // 存储 editor 实例
    const initChangeRef = React.useRef<boolean>(false);

    const url = '/images/upload';
    const toolbarConfig:Partial<IToolbarConfig> = config || {
        toolbarKeys: [
            'undo',
            'redo',
            'headerSelect',
            {
                key: 'group-justify',
                title: '对齐',
                iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
                menuKeys: [
                    'justifyLeft',
                    'justifyRight',
                    'justifyCenter',
                    'justifyJustify',
                ],
            },
            'bold',
            'italic',
            'underline',
            'bulletedList',
            'numberedList',
        ],
    };
    // if (editor) {
    //     console.log(editor?.getAllMenuKeys());
    //     const toolbar = DomEditor.getToolbar(editor);

    //     const curToolbarConfig = toolbar?.getConfig();
    //     console.log(JSON.stringify(curToolbarConfig?.toolbarKeys || {}));
    // }

    const editorConfig: Partial<IEditorConfig> = {
        placeholder: 'input content here...',
        MENU_CONF: {
            uploadImage: {
                maxFileSize: 20 * 1024 * 1024,
                server: url,
                fieldName: 'img-file',
                headers: {
                    authorization: `Bearer ${getUserToken()}`,
                },
            },
        },
    };
    const initialHtml = `
        <html>
            <head>
                <style>
                    /* 添加你的 CSS 样式 */
                    body {
                        font-family: Arial, sans-serif;
                    }
                </style>
            </head>
            <body>
                <h1>Welcome to Your Email Template</h1>
                <p style='color:red;'>This is an example of initial HTML content in the editor.</p>
                <img align="center" alt="" src="https://mcusercontent.com/baca4677cd0bad5dc14733aa4/images/18ee4ac7-136b-9a20-5d71-2a83a76d851c.jpg" width="564" style="max-width:1395px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
            </body>
        </html>
    `;

    return (
        <div className={s.wrap}>
            <div className={s.header}>{header}</div>
            <div className={s.content}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={value || initialHtml}
                    onCreated={setEditor}
                    onChange={(_editor) => {
                        if (!initChangeRef.current) {
                            initChangeRef.current = true;
                            return;
                        }
                        const val = _editor.getHtml();
                        const isEmpty = !val?.replace(/<[^>]*>?/gm, '');
                        if (isClosed) {
                            return;
                        }
                        if (isEmpty) {
                            onChange?.(undefined);
                        } else {
                            onChange?.(_editor.getHtml());
                        }
                    }}
                    mode="default"
                    style={{
                        height: height || '500px',
                        width: '100%',
                        overflowY: 'hidden',
                    }}
                />
            </div>
            <div className={s.footer}>{footer}</div>
        </div>
    );
};

export default HtmlContentInput;
