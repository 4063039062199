import React from 'react';
import { Form, Button, Checkbox, DatePicker, Input, message, Modal, Tag } from 'antd';
import { SCHEDULE } from 'constants/meta';
import { License, ELicenseType } from 'types/common';
import { FormLicense } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import { providerLicenseUpdate } from 'api/provider';
import { opLicenseUpdate } from 'api/operation';
import { formatDate } from 'utils/common';
import { FormMutipleLicense, FormLicenseShadow } from 'types/provider';
import { LicenseData } from 'data/provider';
import FormValidationHelper from 'utils/validation';
import LicenseImg from 'components/LicenseImg';
import StateSelect from 'components/form/StateSelect';
import { EMPTY_PLACEHOLDER, SNAPSHOT_COLOR } from 'constants/common';
import useDeleteLicense from 'hooks/useDeleteLicense';
import { renderChangUI } from 'types/changeHighLight';

type Props = {
    inited?:boolean,
    initValue?: FormMutipleLicense,
    state?:string,
    providerId?:number,
    onChange?: (value:Partial<FormLicense>[])=>void
    viewOnly?:boolean,
    onNullState?: ()=>void,
    isOperation?:boolean,
    refetch?: () => void,
};

const DeaLicense = (props: Props) => {
    const { inited = false, initValue, providerId, onChange, viewOnly, onNullState, isOperation, refetch } = props;
    const [submiting, setSubmiting] = React.useState(false);

    //enable and show modal is little function cover..

    const [enable, setEnable] = React.useState(!!inited);

    //mean already filled and display modal
    const [showModal, setShowModal] = React.useState(false);
    const [form] = Form.useForm<FormMutipleLicense>();
    const [,forceUpdate] = React.useState({});
    const [onRemove, handleRemove] = useDeleteLicense({
        form,
        isOperation,
        filedName: 'licenses',
    });

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            await handleRemove();
            const formValues = await form.validateFields();
            const licenseArrary = formValues.licenses || [];
            const addData:Partial<FormLicense>[] = [];
            for (let i = 0; i < licenseArrary.length; i++) {
                const data = licenseArrary[i];
                const licenseData:Partial<License> = {
                    type: ELicenseType.DEA,
                    secondType: `${i + 1}`,
                    state: data.state,
                    licenseNumber: data.licenseNumber,
                    expireDate: formatDate(data.expireDate),
                    schedule: data.schedule?.join(',') || '',
                    url: data.url,
                    id: data.id,
                };
                const fn = isOperation ? opLicenseUpdate : providerLicenseUpdate;
                // eslint-disable-next-line no-await-in-loop
                const result = await fn(licenseData, providerId!);
                if (result.error) {
                    message.error(result.error);
                    return;
                } else {
                    refetch?.();
                    addData.push(LicenseData.serverToForm(licenseData as License));
                }
            }
            message.success('DEA license info has updated');
            setEnable(true);
            setShowModal(false);
            if (onChange) {
                onChange(addData);
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            form.setFieldsValue(initValue);
            forceUpdate({});
            if (initValue && initValue?.licenses?.length! > 0) {
                setEnable(true);
            }
        }
    }, [initValue]);
    const licensesArray:FormLicense[] = form.getFieldValue('licenses')?.filter((item:FormLicense) => !!(item && item.licenseNumber));

    const hasValue = !!(licensesArray?.length! > 0);
    return (
        <div className={s.wrap}>
            <h3>
                <div className={s.left}>
                    {/* {!enable && (
                        <span
                            onClick={() => {
                                // if (!state) {
                                //     onNullState?.();
                                //     return;
                                // }
                                setShowModal(true);
                            }}
                            className={commonS.addIcon}
                            style={{ marginRight: 16 }}
                        />)} */}
                    <span>DEA Licenses</span>
                </div>
                {!viewOnly &&
                <Button
                    size="small"
                    onClick={() => {
                        // if (!state) {
                        //     onNullState?.();
                        //     return;
                        // }
                        setShowModal(true);
                    }}
                >
                    Edit
                </Button>}
            </h3>
            {
                hasValue ? (
                    <div>
                        {
                            licensesArray?.map((item:FormLicense) => {
                                let borderColor = '#DAD8D8';
                                if ((item as FormLicenseShadow).isNewAdd) {
                                    borderColor = '#87d068';
                                }
                                if ((item as FormLicenseShadow).isDeleted) {
                                    borderColor = 'red';
                                }
                                return (
                                    <div
                                        style={{
                                            borderColor,
                                        }}
                                        className={s.subModule}
                                        key={item.licenseNumber}
                                    >
                                        {
                                            (item as FormLicenseShadow).isNewAdd &&
                                            <Tag className="changed" color="#87d068">New Add</Tag>
                                        }
                                        {
                                            (item as FormLicenseShadow).isDeleted &&
                                            <Tag className="changed" color="red">Is Deleted</Tag>
                                        }
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>License state</div>
                                                <div className={s.value}>{ item.state}</div>
                                                {renderChangUI('state', item, !!isOperation)}
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>DEA license number</div>
                                                <div className={s.value}>{ item.licenseNumber}</div>
                                                {renderChangUI('licenseNumber', item, !!isOperation)}
                                            </div>
                                            <div className={s.displayItem}>
                                                <div className={s.label}>Expiration date</div>
                                                <div className={s.value}>{ item.expireDate?.format('MM-DD-YYYY')}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Schedules this license cover</div>
                                                <div className={s.value}>{ item.schedule?.join(', ') || ''}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '80%',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Uploaded license</div>
                                                <div className={s.value}>
                                                    <LicenseImg src={item.url} />
                                                </div>
                                                {renderChangUI('url', item, !!isOperation, (val:string) => { return <div style={{ border: `1px solid ${SNAPSHOT_COLOR}`, display: 'inline-block', marginTop: '16px' }}><LicenseImg src={val} /></div>; })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>)
                    :
                    (
                        <div>
                            {EMPTY_PLACEHOLDER}
                        </div>
                    )
            }

            <Modal
                title="DEA licenses"
                closable
                className={commonS.modalFixHeightWrap}
                width="80%"
                onCancel={() => {
                    setShowModal(false);
                }}
                okText="Save"
                open={showModal}
                onOk={onSubmit}
                destroyOnClose
                okButtonProps={{ loading: submiting }}
            >
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.List name="licenses" initialValue={[{}]}>
                        {(fields, { add, remove }) => {
                            const { length } = fields;
                            return (
                                <div id="deaLicense">
                                    {fields.map((field) => {
                                        return (
                                            <Form.Item
                                                key={field.key}
                                                noStyle
                                            >
                                                <div className={s.modalWrap}>
                                                    <Form.Item name={[field.name, 'id']}>
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                    <div className={s.row}>
                                                        <div className={s.rowItem}>
                                                            <Form.Item
                                                                style={{ width: '360px' }}
                                                                label="License state"
                                                                name={[field.name, 'state']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'License state is required' },
                                                                ]}
                                                            >
                                                                <StateSelect />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={s.row}>
                                                        <div className={s.rowItem}>
                                                            <Form.Item
                                                                style={{ width: '360px' }}
                                                                label="DEA license number"
                                                                name={[field.name, 'licenseNumber']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'DEA license number is required' },
                                                                    FormValidationHelper.validateLicenseNumber('License number must contain only letters or numbers'),
                                                                ]}
                                                            >
                                                                <Input maxLength={25} placeholder="What is the DEA license number?  " />
                                                            </Form.Item>
                                                        </div>
                                                        <div className={s.rowItem}>
                                                            <Form.Item
                                                                style={{ width: '360px' }}
                                                                label="Expiration date"
                                                                name={[field.name, 'expireDate']}
                                                                rules={[{ required: true, message: 'Expiration date is required' }]}
                                                            >
                                                                <DatePicker
                                                                    disabledDate={(date) => {
                                                                        const now = (new Date()).getTime();
                                                                        if (!date) {
                                                                            return false;
                                                                        }
                                                                        return date?.valueOf() < now;
                                                                    }}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={s.rowItem}>
                                                        <Form.Item
                                                            style={{ width: '500px' }}
                                                            label="Select schedules this license cover"
                                                            name={[field.name, 'schedule']}
                                                            rules={[{ required: false, message: 'Schedules is required' }]}
                                                        >
                                                            <Checkbox.Group
                                                                options={SCHEDULE}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className={s.rowItem}>
                                                        <Form.Item
                                                            style={{ width: '500px' }}
                                                            label="License copy"
                                                            name={[field.name, 'url']}
                                                            rules={[{ required: true, message: 'License copy is required' }]}
                                                        >
                                                            <FileUpload />
                                                        </Form.Item>
                                                    </div>
                                                    {field.name !== 0 &&
                                                    <div className={s.removeWrap}>
                                                        <Button
                                                            onClick={() => {
                                                                onRemove(field.name, () => remove(field.name));
                                                            }}
                                                            danger
                                                            size="small"
                                                        >Remove
                                                        </Button>
                                                    </div> }
                                                    {field.name === length - 1 &&
                                                    <div
                                                        onClick={() => {
                                                            add();
                                                            const wrap = document.getElementById('deaLicense');
                                                            setTimeout(() => {
                                                                wrap?.parentElement?.parentElement?.scroll({
                                                                    top: 1000000,
                                                                    behavior: 'smooth',
                                                                });
                                                            }, 200);
                                                        }}
                                                        className={s.add}
                                                    >
                                                        + Add another DEA license
                                                    </div>
                                                    }
                                                </div>
                                            </Form.Item>);
                                    })}
                                </div>);
                        }}
                    </Form.List>
                </Form>
            </Modal>
        </div>
    );
};

export default DeaLicense;
