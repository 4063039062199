// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--NdmKu {\n  display: flex;\n  flex-direction: row;\n  border-radius: 6px;\n  border: 1px solid #1F87DD;\n  background: var(--light-blue-50, #F0F9FF);\n  padding: 16px;\n}\n.s-module__wrap--NdmKu .s-module__icon--syBBM {\n  flex-shrink: 0;\n}\n.s-module__wrap--NdmKu .s-module__info--uUVvw {\n  flex-grow: 1;\n}\n.s-module__wrap--NdmKu .s-module__check--UxEtu {\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 32px;\n  color: #1F87DD;\n  cursor: pointer;\n}\n.s-module__wrap--NdmKu .s-module__close--itis7 {\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #333;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Notes/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,yCAAA;EACA,aAAA;AACF;AAPA;EAQI,cAAA;AAEJ;AAVA;EAYI,YAAA;AACJ;AAbA;EAgBI,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AAAJ;AAtBA;EA0BI,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AADJ","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: row;\n  border-radius: 6px;\n  border: 1px solid #1F87DD;\n  background: var(--light-blue-50, #F0F9FF);\n  padding: 16px;\n  .icon {\n    flex-shrink: 0;\n  }\n\n  .info {\n    flex-grow: 1;\n  }\n\n  .check {\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0 32px;\n    color: #1F87DD;\n    cursor: pointer;\n  }\n\n  .close {\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #333;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--NdmKu",
	"icon": "s-module__icon--syBBM",
	"info": "s-module__info--uUVvw",
	"check": "s-module__check--UxEtu",
	"close": "s-module__close--itis7"
};
export default ___CSS_LOADER_EXPORT___;
