import React from 'react';
import { Form, Button, DatePicker, Input, message, Modal } from 'antd';
import { License, ELicenseType } from 'types/common';
import { FormLicense } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import { providerLicenseUpdate } from 'api/provider';
import { opLicenseUpdate } from 'api/operation';
import { formatDate } from 'utils/common';
import { LicenseData } from 'data/provider';
import FormValidationHelper from 'utils/validation';
import LicenseImg from 'components/LicenseImg';
import { EMPTY_PLACEHOLDER, REQUIRED_FIELD, SNAPSHOT_COLOR } from 'constants/common';
import Alert from 'components/Alert';
import { renderChangUI } from 'types/changeHighLight';

type Props = {
    inited?:boolean,
    initValue?: FormLicense,
    state?:string,
    providerId?:number,
    onChange?: (value:Partial<FormLicense>)=>void
    viewOnly?:boolean,
    onNullState?: ()=>void,
    isOperation?:boolean,
};

const MalpracticeInsuranceCertificate = (props: Props) => {
    const { inited = false, initValue, providerId, onChange, viewOnly, onNullState, isOperation } = props;
    const [submiting, setSubmiting] = React.useState(false);

    //enable and show modal is little function cover..

    const [enable, setEnable] = React.useState(!!inited);

    //mean already filled and display modal
    const [showModal, setShowModal] = React.useState(false);
    const [form] = Form.useForm<FormLicense>();
    const [currentValue, setCurrentValue] = React.useState<FormLicense>();
    const [,forceUpdate] = React.useState({});

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const data = formValues;
            const licenseData:Partial<License> = {
                type: ELicenseType.MIC,
                secondType: '1',
                licenseNumber: data.licenseNumber,
                expireDate: formatDate(data.expireDate),
                schedule: data.schedule?.join(',') || '',
                url: data.url,
                id: data.id,
            };
            const fn = isOperation ? opLicenseUpdate : providerLicenseUpdate;
            // eslint-disable-next-line no-await-in-loop
            const result = await fn(licenseData, providerId!);
            if (!result.error) {
                message.success('Malpractice insurance certificate license info has updated');
                setEnable(true);
                setShowModal(false);
                if (onChange) {
                    const val:FormLicense = LicenseData.serverToForm(licenseData as License);
                    onChange(val);
                    form.setFieldsValue(val);
                    setCurrentValue(val);
                    forceUpdate({});
                }
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            form.setFieldsValue(initValue);
            setCurrentValue(initValue);
            forceUpdate({});
            if (initValue && initValue?.type) {
                setEnable(true);
            }
        }
    }, [initValue]);
    const hasValue = !!currentValue?.licenseNumber;
    return (
        <div className={s.wrap}>
            <h3>
                <div className={s.left}>
                    {/* {!enable && (
                        <span
                            onClick={() => {
                                // if (!state) {
                                //     onNullState?.();
                                //     return;
                                // }
                                setShowModal(true);
                            }}
                            className={commonS.addIcon}
                            style={{ marginRight: 16 }}
                        />)} */}
                    <span>Malpractice insurance certificate</span>
                </div>
                {!viewOnly &&
                <Button
                    size="small"
                    onClick={() => {
                        // if (!state) {
                        //     onNullState?.();
                        //     return;
                        // }
                        setShowModal(true);
                    }}
                >
                    {hasValue ? 'Edit' : 'Add'}
                </Button>}
            </h3>
            {
                !hasValue &&
                <div className={s.alert}>
                    <Alert text="Malpractice insurance certificate is mandatory in order to be listed on Klarity." />
                </div>
            }
            {
                hasValue ? (
                    <div className={s.subModule} key={currentValue?.licenseNumber}>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Certificate number</div>
                                <div className={s.value}>{ currentValue?.licenseNumber}</div>
                                {renderChangUI('licenseNumber', currentValue, !!isOperation)}
                            </div>
                            <div className={s.displayItem}>
                                <div className={s.label}>Expiration date</div>
                                <div className={s.value}>{ currentValue?.expireDate?.format('MM-DD-YYYY')}</div>
                            </div>
                        </div>

                        <div className={s.row}>
                            <div
                                style={{
                                    width: '80%',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Certificate copy</div>
                                <div className={s.value}>
                                    <LicenseImg src={currentValue?.url} />
                                </div>
                                {renderChangUI('url', currentValue, !!isOperation, (val:string) => { return <div style={{ border: `1px solid ${SNAPSHOT_COLOR}`, display: 'inline-block', marginTop: '16px' }}><LicenseImg src={val} /></div>; })}
                            </div>
                        </div>
                    </div>
                )
                    :
                    (
                        <div>
                            {EMPTY_PLACEHOLDER}
                        </div>
                    )
            }

            <Modal
                title={hasValue ? 'Edit malpractice insurance certificate' : 'Add malpractice insurance certificate'}
                closable
                className={commonS.modalFixHeightWrap}
                width="80%"
                style={{
                    maxWidth: '800px',
                }}
                onCancel={() => {
                    form.resetFields();
                    form.setFieldsValue(currentValue || {});
                    setShowModal(false);
                }}
                okText="Save"
                open={showModal}
                onOk={onSubmit}
                destroyOnClose
                okButtonProps={{ loading: submiting }}
            >
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        noStyle
                    >
                        <div className={s.modalWrap}>
                            <Form.Item name="id">
                                <Input type="hidden" />
                            </Form.Item>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Certificate number"
                                        name={['licenseNumber']}
                                        validateFirst
                                        rules={[
                                            { required: true, message: REQUIRED_FIELD },
                                            FormValidationHelper.validateLicenseNumber('Must contain only letters or numbers'),
                                        ]}
                                    >
                                        <Input maxLength={50} placeholder="" />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Expiration date"
                                        name={['expireDate']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <DatePicker
                                            disabledDate={(date) => {
                                                const now = (new Date()).getTime();
                                                if (!date) {
                                                    return false;
                                                }
                                                return date?.valueOf() < now;
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '500px' }}
                                    label="Certificate copy"
                                    name={['url']}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <FileUpload />
                                </Form.Item>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default MalpracticeInsuranceCertificate;
