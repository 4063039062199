import { useState } from 'react';
import { deleteLicense as deleteLicenseAdmin } from 'api/operation';
import { deleteLicense } from 'api/channel';
import { message } from 'antd';
import { FormInstance } from 'antd/lib/form';

interface IProps {
    form: FormInstance;
    isOperation?: boolean;
    filedName: string;
}

const useDeleteLicense = ({
    form,
    isOperation,
    filedName = 'licenses',
}: IProps):[(index: number, removeCallback: () => void) => void, () => void] => {
    const [pendingRemove, setPendingRemove] = useState<number[]>([]);
    const handleRemove = async () => {
        let deleteApi = deleteLicense;
        if (isOperation) {
            deleteApi = deleteLicenseAdmin;
        }

        const requests = pendingRemove.map((id) => deleteApi(id));

        try {
            const res = await Promise.all(requests);

            if (res.every((e) => !e.error)) {
                setPendingRemove([]);
            } else {
                message.error('Some data was not deleted successfully. Please try again');
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onRemove = async (index: number, removeCallback?: () => void) => {
        const filed = form.getFieldValue(filedName)?.[index];

        if (filed && filed.id) {
            const newPendingRemove = Array.from(new Set([
                ...pendingRemove,
                filed.id,
            ]));
            setPendingRemove(newPendingRemove);
        }

        removeCallback?.();
    };

    return [
        onRemove,
        handleRemove,
    ];
};

export default useDeleteLicense;
