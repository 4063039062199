import React from 'react';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import { Meta } from 'types/common';
import SettingEnumStore from 'store/Enumeration';

const globalSettings:Partial<Record<EnumFields, Meta[]>> = {};

const useSettings = ():[Partial<Record<EnumFields, Meta[]>>, boolean] => {
    const [getSettingEnum] = SettingEnumStore.useStore();
    const settingEnum = getSettingEnum('data');
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<Partial<Record<EnumFields, Meta[]>>>(globalSettings);

    React.useEffect(() => {
        console.log('settingEnum: ', settingEnum);

        if (settingEnum) {
            const newItem:Partial<Record<EnumFields, Meta[]>> = {};
            Object.keys(settingEnum).forEach((field) => {
                const settingData = settingEnum[field as EnumFields]?.map((current:SettingEnum) => {
                    return {
                        label: current.content,
                        value: current.dictKey || current.content,
                    };
                });
                newItem[field as EnumFields] = settingData;
            });
            setItems(newItem);
        }
    }, [settingEnum]);

    return [items, loading];
};

export default useSettings;
