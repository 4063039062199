import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Form, Input, Select } from 'antd';
import { IBasicInfo, EditModuleEnum, FormFields } from 'types/practiceFront';
import StateSelect from 'components/form/StateSelect';
import ErrorBlock from '../ErrorBlock';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import PhotoList from 'components/PhotoList';
import { EMPTY_PLACEHOLDER } from 'constants/common';
import PhotoEditorModal from 'components/PhotoEditorModal';

interface IProps {
    showEditingTip?: boolean;
    isEditing?: boolean;
    initFormValue: IBasicInfo;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    onSubmit: (value: IBasicInfo, field: EditModuleEnum) => void;
}

const BasicInfo = ({
    showEditingTip,
    isEditing,
    initFormValue,
    onEditStatusChange,
    onSubmit,
}: IProps) => {
    const [credentialTypes] = useGetSettingData(EnumFields.CREDENTIAL_TYPE);
    const [basicInfoForm] = Form.useForm<IBasicInfo>();

    useEffect(() => {
        if (initFormValue) {
            basicInfoForm.setFieldsValue(initFormValue);
        }
    }, [initFormValue, basicInfoForm]);

    const { firstName, lastName, credential, title, photo, id } = initFormValue || {};

    const providerName = `${firstName} ${lastName}`;
    // const addressDetail = `${address}, ${city}, ${state}`;

    const handleSubmit = useCallback(() => {
        basicInfoForm.validateFields().then((values) => {
            onSubmit({
                ...values,
                credential: values.credential?.join(','),
            }, EditModuleEnum.BASICINFO);
        }).catch((e) => {
            console.error(e);
        });
    }, [basicInfoForm, onSubmit]);

    return (
        <div className={s.wrap} id={EditModuleEnum.BASICINFO}>
            <div className={s.header}>
                <div className={s.title}>Basic Info</div>
                <div className={s.btns}>
                    {
                        isEditing ?
                            <>
                                <Button onClick={() => onEditStatusChange(false, EditModuleEnum.BASICINFO)}>Cancel</Button>
                                <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                            </>
                            : <Button onClick={() => onEditStatusChange(true, EditModuleEnum.BASICINFO)}>Edit</Button>
                    }
                </div>
            </div>
            {
                showEditingTip && isEditing && <ErrorBlock />
            }
            <div className={s.body}>
                {
                    !isEditing ?
                        (
                            <div className={s.viewWrap}>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Name</div>
                                    <div className={s.value}>{providerName || EMPTY_PLACEHOLDER}</div>
                                </div>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Title</div>
                                    <div className={s.value}>{title || EMPTY_PLACEHOLDER}</div>
                                </div>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Credentials</div>
                                    <div className={s.value}>{credential?.join(',') || EMPTY_PLACEHOLDER}</div>
                                </div>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Practice Logo</div>
                                    <div className={s.value}>
                                        {
                                            photo ? <img className={s.logo} src={photo} /> : EMPTY_PLACEHOLDER
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className={s.formWrap}>
                                <Form
                                    form={basicInfoForm}
                                    name={EditModuleEnum.BASICINFO}
                                    className={commonS.formStyle1}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className={s.itemBox}>
                                        <div className={s.item} style={{ width: '45%' }}>
                                            <Form.Item
                                                label="First Name"
                                                name={FormFields.FIRST_NAME}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'first name is a required field' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="first name" />
                                            </Form.Item>
                                        </div>
                                        <div className={s.item} style={{ width: '45%' }}>
                                            <Form.Item
                                                label="Last Name"
                                                name={FormFields.LAST_NAME}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'last name is a required field' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="last name" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Title"
                                                name={FormFields.TITLE}
                                                rules={[
                                                    { max: 10, message: 'Exceeded maximum character length of 10' },
                                                ]}
                                            >
                                                <Input placeholder="Ex. Dr., Sr., Hon., Mr., Mrs., Ms." />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Credentials"
                                                name={FormFields.CREDENTIAL}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'Credentials is a required field' },
                                                ]}
                                            >
                                                <Select placeholder="search and select your credentials" mode="multiple" fieldNames={{ label: 'content', value: 'dictKey' }} options={credentialTypes} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Practice Logo"
                                                name={FormFields.PHOTO}
                                            >
                                                <PhotoEditorModal
                                                    mode="inner"
                                                    providerId={Number(id)}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default BasicInfo;
