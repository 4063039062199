// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--xvWnz .s-module__profileAvatar--Pd4aR {\n  display: flex;\n  align-items: center;\n}\n.s-module__wrap--xvWnz .s-module__profileAvatar--Pd4aR .s-module__avatar--Q9rdZ {\n  margin-right: 24px;\n  width: 245px;\n  height: 245px;\n  border-radius: 50%;\n}\n.s-module__previewBox--DwOwi {\n  display: flex;\n  align-items: center;\n}\n.s-module__previewBox--DwOwi .s-module__previewImage--VQxuN {\n  margin-right: 24px;\n  width: 200px;\n  height: 200px;\n  border-radius: 50%;\n}\n.s-module__previewBox--DwOwi .s-module__btns--zFFWQ .s-module__showEditModalBtn--yUkNc {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PhotoEditorModal/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,aAAA;EACA,mBAAA;AAAR;AAHA;EAKY,kBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AACZ;AAIA;EACI,aAAA;EACA,mBAAA;AAFJ;AAAA;EAIQ,kBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AADR;AANA;EAWY,kBAAA;AAFZ","sourcesContent":[".wrap {\n    .profileAvatar {\n        display: flex;\n        align-items: center;\n        .avatar {\n            margin-right: 24px;\n            width: 245px;\n            height: 245px;\n            border-radius: 50%;\n        }\n    }\n}\n\n.previewBox {\n    display: flex;\n    align-items: center;\n    .previewImage {\n        margin-right: 24px;\n        width: 200px;\n        height: 200px;\n        border-radius: 50%;\n    }\n    .btns {\n        .showEditModalBtn {\n            margin-right: 16px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--xvWnz",
	"profileAvatar": "s-module__profileAvatar--Pd4aR",
	"avatar": "s-module__avatar--Q9rdZ",
	"previewBox": "s-module__previewBox--DwOwi",
	"previewImage": "s-module__previewImage--VQxuN",
	"btns": "s-module__btns--zFFWQ",
	"showEditModalBtn": "s-module__showEditModalBtn--yUkNc"
};
export default ___CSS_LOADER_EXPORT___;
