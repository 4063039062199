import React, { useCallback } from 'react';
import s from './s.module.less';
import { Tooltip } from 'antd';
import uniprofileAIResumebg from 'assets/uniprofileResume/uniprofileAIResumeBg.png';

interface IProps {
    type?: number | null;
    onClick: (v: number) => void;
}

const UniprofileResume = ({
    type,
    onClick,
}: IProps) => {
    const handleClick = useCallback((v: number) => {
        onClick(v);
    }, [onClick]);

    return (
        <div className={s.wrap}>
            <div className={s.left}>
                <div className={s.inroBox}>
                    <img className={s.introImage} src={uniprofileAIResumebg} />
                </div>
            </div>
            <div className={s.right}>
                <div className={s.title}>Unlock the Power of Kiwi Health with UniProfile</div>
                <div className={s.desc}>Kickstart your Kiwi journey with a comprehensive UniProfile. This all-in-one profile simplifies your practice management, launches your website via Practice Front, and connects you to various channels. Choose efficiency with Ava, the kiwi AI: just upload your resume, and Ava quickly crafts your profile, saving you valuable time and streamlining setup.</div>
                <div className={s.typeSelection}>
                    <div className={`${s.typeOption} ${type === 1 && s.selected}`} onClick={() => handleClick(1)}>
                        <div className={s.optionInner}>
                            <div className={s.aiImage} />
                            <div className={s.textContent}>
                                <div className={s.title}>
                                    <div className={s.titleText}>Create you UniProfile with</div>
                                    <div className={s.avaLogo}>Ava</div>
                                    <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '4px 8px' }} color="#FFFFFF" title={<div className={s.tipText}>Ava is your AI assistant in Kiwi Health</div>}>
                                        <div className={s.questionMarkIcon} />
                                    </Tooltip>
                                    <div className={s.label}>Popular</div>
                                </div>
                                <div className={s.desc}>Save time by uploading your resume. Ava will extract the necessary information to create your profile. Recommended for a faster setup – save up to 15 minutes!</div>
                            </div>
                            <div className={s.checkbox} />
                        </div>
                    </div>
                    <div className={`${s.typeOption} ${type === 2 && s.selected}`} onClick={() => handleClick(2)}>
                        <div className={s.optionInner}>
                            <div className={s.manuallyImage} />
                            <div className={s.textContent}>
                                <div className={s.title}>
                                    <div className={s.titleText}>Manually fill out your profile</div>
                                </div>
                                <div className={s.desc}>Prefer to input your details manually? Complete our form to create your UniProfile step by step.</div>
                            </div>
                            <div className={s.checkbox} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UniprofileResume;
